import { render, staticRenderFns } from "./ProductsListModal.vue?vue&type=template&id=954b68f6&scoped=true&"
import script from "./ProductsListModal.vue?vue&type=script&lang=ts&"
export * from "./ProductsListModal.vue?vue&type=script&lang=ts&"
import style0 from "./ProductsListModal.vue?vue&type=style&index=0&id=954b68f6&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "954b68f6",
  null
  
)

export default component.exports