















































































































import AccountModule from '@/store/modules/account';
import {
  Component, Vue, Prop, Model, Emit,
} from 'vue-property-decorator';
import {
  Checkbox, Button, Input, Modal, InputNumber,
} from 'ant-design-vue';
import { productListByOrderNo } from '@/services/apis/order';
import {
  ProductListByOrderNoResponseWithStatistics, Supplier, ProductForTable,
} from '@/services/apis/order.d';

Vue.use(Checkbox);
Vue.use(Button);
Vue.use(Input);
Vue.use(Modal);
Vue.use(InputNumber);

@Component({
  name: 'delivery-order',
})
export default class DeliveryOrder extends Vue {
  @Model('close', { type: Boolean, default: true })
  readonly isShow!: boolean;

  @Emit('close')
  // eslint-disable-next-line class-methods-use-this
  handleClose(): boolean {
    return false;
  }

  @Prop({ type: String })
  readonly orderNo!: string;

  @Emit('submit')
  // eslint-disable-next-line class-methods-use-this
  handleSubmit(
    data: ProductListByOrderNoResponseWithStatistics,
  ): ProductListByOrderNoResponseWithStatistics {
    return data;
  }

  isCheckedAll = false;

  productList: ProductForTable[] = [];

  supplier: Supplier = {
    id: 0,
    companyName: '', // 实际可能返回的companyName
  };

  totalPrice = '';

  sumType = 0;

  sumCount = 0;

  async mounted(): Promise<void> {
    try {
      const res = (await productListByOrderNo(this.orderNo)).data;
      const list = res.productList;
      this.productList = list.map((v) => {
        this.$set(v, 'count', v.remainCount);
        this.$set(v, 'checked', false);
        return v;
      }) as ProductForTable[];
      this.supplier = res.supplier;
    } catch (err) {
      this.$message.error(err || '数据获取失败');
    }
  }

  handlePreview(url: any): void {
    return this && AccountModule.setPreviewImgUrl(url);
  }

  handleCheckAllChange({ target }: { target: { checked: boolean } }): void {
    const ck = target.checked;
    this.productList.map((v) => {
      if (v.remainCount > 0) {
        this.$set(v, 'checked', ck);
      }
      return ck;
    });
    this.getTotalPriceAndSum();
  }

  handleSubmitClick(): void {
    const list = this.productList.filter((v) => v.checked);
    if (!list.length) {
      this.$message.error('请选择商品');
      return;
    }
    this.handleSubmit({
      supplier: this.supplier,
      productList: list,
      totalPrice: Number(this.totalPrice),
      sumType: this.sumType,
      sumCount: this.sumCount,
    });
  }

  handleCheckboxChange(
    { target }: { target: { checked: boolean } },
    idx: number,
    i: number,
  ): void {
    this.$nextTick(() => {
      this.productList[idx].checked = target.checked;
      this.getTotalPriceAndSum();
    });
  }

  handleInputNumberChange(): void {
    this.getTotalPriceAndSum();
  }

  getTotalPriceAndSum(): void {
    let price = 0;
    let sum = 0;
    let count = 0;
    this.productList.forEach((k) => {
      if (k.checked) {
        price += Number((k.count * Number(k.price)).toFixed(2));
        sum += 1;
        count += k.count;
      }
    });
    this.totalPrice = price.toFixed(2);
    this.sumType = sum;
    this.sumCount = count;
  }
}
